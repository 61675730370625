.silnet-logo-container {

    .silnet-logo-svg {
        width: auto;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        overflow: visible;

        @at-root .silnet-logo-container.silnet-white .silnet-logo-svg.animate g.silnet-logo-circle .silnet-logo-path {
            animation-name: "silnet-white-logo-path-animation";
        }

        g.silnet-logo-circle {
            transform-origin: 50% 50%;
            animation-duration: 4s;
            animation-iteration-count: 1;
            animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

            > * {
                transition: 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
            }

            .silnet-logo-background {
                fill: rgba(255, 255, 255, 0);
                animation-duration: 3s;
                animation-delay: 1s;
                animation-iteration-count: 1;
                animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

                @at-root .silnet-logo-container .silnet-logo-svg:hover g.silnet-logo-circle .silnet-logo-background {
                    fill: rgba(255, 255, 255, 1);
                }
            }

            .silnet-logo-path {
                fill: rgba(0, 0, 0, 0.3);
                animation-duration: 3s;
                animation-delay: 1s;
                animation-iteration-count: 1;
                animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

                @at-root .silnet-logo-container.silnet-white .silnet-logo-svg g.silnet-logo-circle .silnet-logo-path {
                    fill: rgba(255, 255, 255, 0.5);
                }

                @at-root .silnet-logo-container .silnet-logo-svg:hover g.silnet-logo-circle .silnet-logo-path {
                    fill: #ed1c24 !important;
                }
            }
        }

        .silnet-logo-automotive-separator {
            fill: rgba(0, 0, 0, 0.2);

            @at-root .silnet-logo-container.silnet-white .silnet-logo-svg .silnet-logo-automotive-separator {
                fill: rgba(255, 255, 255, 0.3);
            }
        }

        .silnet-logo-automotive-text-red {
            fill: rgba(0, 0, 0, 0.5);

            @at-root .silnet-logo-container.silnet-white .silnet-logo-svg .silnet-logo-automotive-text-red {
                fill: rgba(255, 255, 255, 0.5);
            }

            @at-root .silnet-logo-container .silnet-logo-svg:hover .silnet-logo-automotive-text-red {
                fill: #ed1c24 !important;
            }
        }

        .silnet-logo-automotive-text-gray {
            fill: rgba(0, 0, 0, 0.5);

            @at-root .silnet-logo-container.silnet-white .silnet-logo-svg .silnet-logo-automotive-text-gray {
                fill: rgba(255, 255, 255, 0.5);
            }
        }

        &.animate {

            g.silnet-logo-circle {
                animation-name: "silnet-logo-circle-animation";

                .silnet-logo-background {
                    animation-name: "silnet-logo-background-animation";
                }

                .silnet-logo-path {
                    animation-name: 'silnet-logo-path-animation';
                }
            }
        }
    }
}

@keyframes silnet-logo-circle-animation {

    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }

    60%,
    80% {
        transform: rotate3d(0, 1, 0, 360deg);
    }

    85% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1.2);
    }

    90% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1);
    }

    95% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1.2);
    }

    100% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1);
    }
}

@keyframes silnet-logo-background-animation {

    0% {
        fill: rgba(255, 255, 255, 0);
    }

    40%,
    94% {
        fill: rgba(255, 255, 255, 1);
    }

    100% {
        fill: rgba(255, 255, 255, 0);
    }
}

@keyframes silnet-logo-path-animation {

    0% {
        fill: rgba(0, 0, 0, 0.3);
    }

    40%,
    94% {
        fill: #ed1c24;
    }

    100% {
        fill: rgba(0, 0, 0, 0.3);
    }
}

@keyframes silnet-white-logo-path-animation {

    0% {
        fill: rgba(255, 255, 255, 0.5);
    }

    40%,
    94% {
        fill: #ed1c24;
    }

    100% {
        fill: rgba(255, 255, 255, 0.5);
    }
}
