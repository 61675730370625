@mixin basic-transition($duration:0.3s, $delay:0s) {
	transition: $duration cubic-bezier(0.165, 0.84, 0.44, 1) $delay;
}

@mixin js-reveal($duration:1.2s) {
	opacity: 0;
	transform: translate3d(0, -20px, 0);
	@include basic-transition($duration);

	&.show, &.open {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
