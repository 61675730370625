section.hero {

    position: relative;
    z-index: 0;

    margin-top: 64.45px; // Default mobile header height. Corrected the moment JS loads.

    &.hero-home {
        @each $breakpoint, $hero-margin-bottom in $hero-home-margins-bottom {
            @include media-breakpoint-up($breakpoint) {
                margin-bottom: $hero-margin-bottom;
            }
        }
    }

    &.hero-page {
        @each $breakpoint, $hero-margin-bottom in $hero-page-margins-bottom {
            @include media-breakpoint-up($breakpoint) {
                margin-bottom: $hero-margin-bottom;
            }
        }
    }

    .swiper-slide {
        .slide-background {
            background-size: 125%;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $gray-100;
            padding-top: 30.729%;
            position: relative;

            @include media-breakpoint-up(lg) {
                background-size: cover;
            }

            @at-root section.hero.hero-home .swiper-slide .slide-background {
                padding-top: 30.729%;
            }

            @at-root section.hero.hero-page .swiper-slide .slide-background {
                // TODO: This can probably be done a little bit better.
                padding-top: 0;
                min-height: 130px;
                height: 16.7vw;
            }

            .swiper-lazy-preloader {
                @each $breakpoint,
                    $hero-margin-bottom in $hero-home-margins-bottom
                {
                    @include media-breakpoint-up($breakpoint) {
                        top: calc(50% - #{abs($hero-margin-bottom) / 2});
                    }
                }
            }

            .slide-content {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;

                @at-root section.hero.hero-home .swiper-slide .slide-background .slide-content {
                    @each $breakpoint,
                        $hero-margin-bottom in $hero-home-margins-bottom
                    {
                        @include media-breakpoint-up($breakpoint) {
                            height: calc(100% - #{abs($hero-margin-bottom)});
                        }
                    }
                }

                @at-root section.hero.hero-page .swiper-slide .slide-background .slide-content {
                    @each $breakpoint,
                        $hero-margin-bottom in $hero-page-margins-bottom
                    {
                        @include media-breakpoint-up($breakpoint) {
                            height: calc(100% - #{abs($hero-margin-bottom)});
                        }
                    }
                }

                .slide-title,
                .slide-subtitle {
                    @include js-reveal();
                }

                .slide-title {
                    @include responsive-font-size(2.4rem);
                    line-height: 1.2em;
                    font-weight: $font-weight-bold;

                    @include media-breakpoint-up(lg) {
                        width: 50%;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-left: 3rem;
                    }
                }

                .slide-subtitle {
                    @include responsive-font-size(1.3rem);
                    font-weight: 600;
                    margin-top: 1vw;

                    @include media-breakpoint-up(lg) {
                        width: 50%;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-left: 3rem;
                    }
                }
            }
        }
    }
}
