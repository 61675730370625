.custom-select:invalid {
    color: $gray-500;

    option {
        color: $body-color;
    }
}

.custom-file-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 100px;
}

.form-with-dark-background {
    .form-control {
        border: none;
    }

    &.was-validated :invalid ~ .invalid-feedback,
    &.was-validated :invalid ~ .invalid-tooltip,
    .is-invalid ~ .invalid-feedback,
    .is-invalid ~ .invalid-tooltip {
        padding: 1px 5px;
        display: inline-block;
        width: auto;
        color: white;
        font-weight: 600;
        background: $danger;
    }

    &.was-validated :invalid ~ .valid-feedback,
    &.was-validated :invalid ~ .valid-tooltip,
    .is-invalid ~ .valid-feedback,
    .is-invalid ~ .valid-tooltip {
        padding: 1px 5px;
        display: inline-block;
        width: auto;
        color: white;
        font-weight: 600;
        background: $success;
    }

    .custom-control-label::before {
        border-color: #fff;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #fff;
    }

    &.was-validated .custom-control-input:invalid ~ .custom-control-label,
    &.was-validated .custom-control-input:valid ~ .custom-control-label,
    &.was-validated .form-check-input:invalid ~ .form-check-label,
    &.was-validated
        .form-check-input:valid
        ~ .form-check-label
        .custom-control-input.is-invalid
        ~ .custom-control-label,
    .custom-control-input.is-valid ~ .custom-control-label,
    .form-check-input.is-invalid ~ .form-check-label,
    .form-check-input.is-valid ~ .form-check-label {
        color: inherit;
    }

    // .custom-control-input:checked ~ .custom-control-label::before {
    //     border-color: #fff;
    //     background-color: #fff;
    // }
}
