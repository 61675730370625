$contact-block-height-md: 85px;
$contact-block-height-lg: 167px;

section.company {
    margin-bottom: -($contact-block-height-md / 2); // Ugly, I know.
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        margin-bottom: -($contact-block-height-lg / 2);
    }

    // h2 {
    //     @include responsive-font-size(2.5rem);
    //     @include pulse();
    //     font-weight: $font-weight-bold;
    //     text-align: center;
    // }

    .row {
        &.with-image {
            @include media-breakpoint-up(md) {
                &:after {
                    content: '';
                    margin-top: -6rem;
                    width: 30%;
                    background-image: url('../images/smiling-man.jpg');
                    background-repeat: no-repeat;
                    background-position: 110% 100%;
                    background-size: contain;
                    position: relative;
                    right: -1rem;
                    flex: 1;
                }
            }

            .company-description-text {
                // padding-bottom: $spacer * 4;

                p {
                    margin-bottom: $spacer * 2;
                    // line-height: 1.7;

                    @include media-breakpoint-up(lg) {
                        text-align: justify;
                    }
                }
            }
        }

        &.contact-blocks {
            > div {
                a {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    height: $contact-block-height-md;
                    @include responsive-font-size(1.5rem);
                    // padding: $spacer * 2;
                    @include basic-transition();

                    @include media-breakpoint-up(lg) {
                        height: $contact-block-height-lg;
                    }

                    &:hover {
                        text-decoration: none;
                        transform: translate3d(0, -$spacer, 0);
                    }

                    &.contact-block-meeting {
                        background-color: $primary;
                        background-image: url('../images/contact-block-meeting.jpg');
                        background-repeat: no-repeat;
                        background-position: right center;
                        background-size: cover;
                    }

                    &.contact-block-get-to-know-us {
                        background-color: #363636;
                        background-image: url('../images/contact-block-get-to-know-us.jpg');
                        background-repeat: no-repeat;
                        background-position: right center;
                        background-size: cover;
                    }

                    .block-icon {
                        margin-right: $spacer;
                        font-size: 1.75rem;

                        @include media-breakpoint-up(sm) {
                            margin-right: $spacer * 2;
                            font-size: 2.5rem;
                        }

                        @include media-breakpoint-up(lg) {
                            margin-right: $spacer * 2;
                            font-size: 3rem;
                        }
                    }

                    .label {
                        line-height: 1.2;

                        .svg-inline--fa {
                            display: inline-block;
                            margin-left: $spacer / 2;
                        }
                    }
                }
            }
        }
    }
}