.offer-element {
    .heading-container {
        background-color: $primary;

        @include media-breakpoint-up(md) {
            height: 7rem;
            display: flex;
            align-items: center;
        }
    }

    .triangle-container {
        border-right: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        border-left: 1px solid $gray-200;

        @include triangle('top', 'outside', 1.5rem, #fff, $red);
    
        @include media-breakpoint-up(sm) {
            @include triangle('top', 'outside', 2rem, #fff, $red);
        }
    }
}