.ccm-page > header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 20;
    background: #fff;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
    @include basic-transition();

    @at-root html.ccm-toolbar-visible .ccm-page > header {
        top: 48px;
    }

    @at-root html.scrolled .ccm-page > header {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }

    @at-root html.scrolled .ccm-page > header .row-header {
        align-items: center !important;
    }

    .site-logo {
        width: 25%;
        max-width: 260px;
        flex-shrink: 1;
        @include basic-transition();

        padding: 0.5rem 0;

        @include media-breakpoint-up(md) {
            padding: 1rem 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem 0;
        }

        @at-root html.scrolled .ccm-page > header .site-logo {
            max-width: 130px;
            // transform: scale(0.6);
            // transform-origin: 0% 50%;

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .contact-cta {
            padding: 0.5rem 0.75rem;
        }
    }

    .col-header-right {
        @at-root html.scrolled .ccm-page > header .col-header-right {
            flex-direction: row-reverse !important;

            align-items: center !important;
	        justify-content: space-around !important;
        }

        .navbar {
            &.navbar-icons {
                padding: 0;
                font-size: 1.2rem;

                .nav-link {
                    padding: 0.5rem;

                    &:hover {
                        color: $body-color;
                    }
                }
            }

            &.navbar-standard {
                .navbar-nav {
                    margin-left: $spacer;

                    .nav-item .nav-link {
                        font-weight: 600;
                        padding: 1.75rem 1rem;
                        white-space: nowrap;
                        position: relative;

                        @include media-breakpoint-down(lg) {
                            padding: 1.5rem 0.5rem;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            width: 0%;
                            height: 10px;
                            background-color: $red;
                            @include basic-transition();
                            opacity: 0;
                            transform: translate3d(-50%, 0, 0);
                        }

                        &:hover:after {
                            width: 100%;
                            opacity: 1;
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
}
