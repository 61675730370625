@mixin triangle(
    $position: 'top',
    $mode: 'inside',
    $size: 4rem,
    $backgroundColor: #fff,
    $iconColor: $red
) {
    &.triangle-container {

        position: relative;

        @if $position == 'top' {
            padding-top: $size;
        } @else if $position == 'bottom' {
            padding-bottom: $size;
        }

        .triangle-inner {
            background: $backgroundColor;
            // padding-left: $grid-gutter-width / 2;
            // padding-right: $grid-gutter-width / 2;
            padding-left: $size;
            padding-right: $size;
            position: relative;
            z-index: 2;

            // @include media-breakpoint-up(sm) {
            //     padding-left: $size;
            //     padding-right: $size;
            // }

            @if $position == 'top' {
                padding-bottom: $size;
            } @else if $position == 'bottom' {
                padding-top: $size;
            }
        }

        @if $mode == 'inside' {
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                left: -($size / 2);
                background: $backgroundColor;
                height: $size * 1.05;
                width: calc(50% + #{$size / 4});
                @include basic-transition();

                @if $position == 'top' {
                    top: 0;
                    transform: skewX(45deg);
                } @else if $position == 'bottom' {
                    bottom: 0;
                    transform: skewX(-45deg);
                }
            }

            &:after {
                content: '';
                position: absolute;
                right: -($size / 2);
                background: $backgroundColor;
                height: $size * 1.05;
                width: calc(50% + #{$size / 4});
                @include basic-transition();

                @if $position == 'top' {
                    top: 0;
                    transform: skewX(-45deg);
                } @else if $position == 'bottom' {
                    bottom: 0;
                    transform: skewX(45deg);
                }
            }
        } @else if $mode == 'outside' {
            background-color: $backgroundColor;

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                background: $backgroundColor;
                height: $size;
                width: $size;
                transform: translate3d(-50%, -50%, 0) rotate(45deg);

                @if $position == 'top' {
                    top: 0;
                } @else if $position == 'bottom' {
                    bottom: 0;
                }
            }
        }

        .triangle-icon {
            position: absolute;
            left: 50%;
            @include responsive-font-size(0.9rem);
            color: $iconColor;
            transform: translate3d(-50%, 0, 0);
            @include basic-transition();
            
            @include media-breakpoint-up(lg) {
                font-size: 1.4rem;
            }

            @if $position == 'top' {
                top: -0.25em;
            } @else if $position == 'bottom' {
                bottom: -0.25em;
            }
        }
    }
}

@mixin triangle-colors($backgroundColor, $iconColor) {
    .triangle-icon {
        color: $iconColor;
    }

    &:before,
    &:after {
        background: $backgroundColor;
    }

    .triangle-inner {
        background: $backgroundColor;
    }
}
