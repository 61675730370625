.figure {
    a {
        .figure-img-container {
            position: relative;
            display: flex;
            margin-bottom: 0.5rem;

            .magnifier {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba($primary, 0.5);
                opacity: 0;
                @include basic-transition();

                svg {
                    font-size: 4rem;
                    color: white;
                    @include basic-transition();
                    transform: scale(0.75);
                }
            }

            .figure-img {
                margin-bottom: 0;
            }
        }

        &:hover .figure-img-container {
            .magnifier {
                opacity: 1;

                svg {
                    transform: scale(1);
                }
            }
        }
    }
}