section.bottom-blocks {
    @include triangle('top', 'outside', 4rem, #fff, $red);

    @include media-breakpoint-up(lg) {
        padding-bottom: 4rem;
    }

    .block-item {
        background-color: $gray-500;
        @include basic-transition();
        position: relative;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;

        &:before {
            content: '';
            background-color: $gray-800;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            z-index: 1;
            @include basic-transition();
        }

        .block-header {
            display: block;
            position: relative;
            z-index: 2;
            @include responsive-font-size(1.7rem);
            font-weight: 600;
            line-height: 1.2;
            // margin-top: $spacer * 2;
            @include basic-transition();

            &:after {
                content: '';
                width: 50px;
                height: 0;
                display: block;
                margin: ($spacer * 1.5) auto;
                border-bottom: 1px solid #fff;
                @include basic-transition();
            }
        }

        .block-text {
            position: relative;
            z-index: 2;
            margin-top: $spacer * 2;
            @include responsive-font-size(1.2rem);
            line-height: 1.2;
            // font-weight: 600;
            color: #fff;
            @include basic-transition();
        }

        .block-date {
            position: relative;
            z-index: 2;
            margin-top: $spacer;
            font-size: 0.8rem;
            margin-bottom: $spacer;
            color: #fff;
            @include basic-transition();
        }

        .btn {
            position: absolute;
            z-index: 2;
            left: 50%;
            bottom: 0;
            @include basic-transition();
            transform: translate3d(-50%, 50%, 0);
        }

        &:hover {
            text-decoration: none;

            &:before {
                background-color: $primary;
                opacity: 0.8;
            }

            .block-header {
                &:after {
                    width: 100px;
                }
            }

            .btn {
                background-color: $gray-900;
                border-color: $gray-900;
            }
        }
    }
}