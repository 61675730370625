.ccm-page {
    position: relative;
    overflow: hidden;
}

.container .inner {
    padding: 0 15px 0 15px;

    @include media-breakpoint-up(sm) {
        padding: 0 2rem 0 2rem;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 4rem 0 4rem;
    }
}

.main-top-triangle {
    @include triangle('top', 'inside', 15px, #fff, $red);
    
    @include media-breakpoint-up(sm) {
        @include triangle('top', 'inside', 2rem, #fff, $red);
    }
    
    @include media-breakpoint-up(lg) {
        @include triangle('top', 'inside', 4rem, #fff, $red);
    }
}

.pulse-heading {
    @include responsive-font-size(2.5rem);
    @include pulse();
    font-weight: $font-weight-bold;
    text-align: center;
}

.ccm-page table td {
    &:nth-child(even) {
        padding-left: 50px;
    }
}