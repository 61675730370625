@mixin clearfix() {
    &:after,
    &:before {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}
