section.bottom-form {
    color: #fff;

    @include triangle('top', 'inside', 1.5rem, $primary, $red);
    
    @include media-breakpoint-up(sm) {
        @include triangle('top', 'inside', 2rem, $primary, $red);
    }
    
    @include media-breakpoint-up(lg) {
        @include triangle('top', 'inside', 4rem, $primary, $red);
    }

    h3 {
        @include responsive-font-size(2.3rem);
    }

    h4 {
        @include responsive-font-size(1.5rem);
    }

    a {
        color: #fff;
        text-decoration: underline;

        &:hover {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}