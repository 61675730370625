section.advantages {
    .inner {
        .advantage-item {
            background: $gray-100;
            height: 100%;
            @include basic-transition();

            .advantage-icon {
                color: $gray-900;
                font-size: 3rem;
                @include basic-transition();

                @include media-breakpoint-up(lg) {
                    font-size: 4.7rem;
                }
            }

            .advantage-header {
                display: block;
                @include responsive-font-size(1.7rem);
                font-weight: 700;
                color: $primary;
                margin-top: $spacer;
                @include basic-transition();

                @include media-breakpoint-up(lg) {
                    margin-top: $spacer * 2;
                }

                &:after {
                    content: '';
                    width: 50px;
                    height: 0;
                    display: block;
                    margin: $spacer * 0.75 auto ($spacer) auto;
                    border-bottom: 1px solid $gray-900;
                    @include basic-transition();

                    @include media-breakpoint-up(lg) {
                        margin: $spacer auto ($spacer * 1.5) auto;
                    }
                }
            }

            .advantage-text {
                color: $body-color;
                @include basic-transition();
            }

            &:hover {
                background: $primary;
                text-decoration: none;

                .advantage-icon {
                    color: #fff;
                }

                .advantage-header {
                    color: #fff;
    
                    &:after {
                        border-color: #fff;
                        width: 100px;
                    }
                }

                .advantage-text {
                    color: #fff;
                }
            }
        }
    }
}