section.offer {
    background: $gray-100;
    padding-top: ($contact-block-height-md / 2);
    padding-bottom: 4rem;

    @include media-breakpoint-up(lg) {
        padding-top: ($contact-block-height-lg / 2);
    }

    // h2 {
    //     @include responsive-font-size(2.5rem);
    //     @include pulse();
    //     font-weight: $font-weight-bold;
    //     text-align: center;
    // }

    .inner {
        // padding-top: 3rem;

        .offer-blocks {
            > div {
                a {
                    @include triangle('bottom', 'inside', 2rem, #fff, #000);
                    @include basic-transition();
                    display: block;
                    text-align: center;
                    height: 100%;

                    .triangle-inner {
                        @include basic-transition();
                        padding-top: $spacer * 3;
                        padding-bottom: $spacer;

                        .offer-icon {
                            color: $red;
                            margin-top: $spacer;
                            margin-bottom: $spacer * 2;
                            @include responsive-font-size(5rem);
                        }

                        .label {
                            color: $body-color;
                            font-weight: 600;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                        @include triangle-colors($primary, $red);

                        .triangle-inner {

                            .offer-icon {
                                color: #fff;
                            }

                            .label {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
