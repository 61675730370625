.accordion {
    .card {
        .card-header {
            padding: 0;

            .btn-trigger {
                background-color: transparent;

                .fa-angle-down {
                    @include basic-transition();
                }

                &:hover {
                    text-decoration: none;
                    background-color: darken($card-cap-bg, 5%);
                }

                &:focus {
                    text-decoration: none;
                }

                &[aria-expanded='true'] {
                    background-color: darken($card-cap-bg, 5%);

                    .fa-angle-down {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
