.ccm-page:before {
    content: '';
    z-index: -1;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: z-index 0s 0.4s linear, background 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mm-wrapper_opening .ccm-page:before {
    z-index: 30;
    transition: background 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    background: rgba(0, 0, 0, 0.5);
}