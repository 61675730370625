section.contact {
    color: #fff;

    @include triangle('top', 'inside', 1.5rem, $primary, $red);
    
    @include media-breakpoint-up(sm) {
        @include triangle('top', 'inside', 2rem, $primary, $red);
    }
    
    @include media-breakpoint-up(lg) {
        @include triangle('top', 'inside', 4rem, $primary, $red);
    }

    a {
        color: #fff;
        text-decoration: underline;

        &:hover {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .contact-data {
        h3 {
            @include responsive-font-size(2.3rem);
        }

        @include media-breakpoint-down(md) {
            margin-bottom: $spacer * 1.5;
            padding-bottom: $spacer * 1.5;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
    }
}