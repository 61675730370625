.ccm-page > footer {
    .footer-text {
        color: $gray-500;

        .copyright {
            .first-line {
                @include media-breakpoint-up(lg) {
                    &:after {
                        content: '\00a0-\00a0';
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                &:after {
                    content: '\00a0\00a0|\00a0\00a0';
                }
            }
        }

        .footer-links {
            margin: 0;
            padding: 0;

            li {
                display: block;

                &:after {
                    content: '\00a0\00a0|\00a0\00a0';
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        a {
            color: inherit;
            // text-decoration: underline;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            border-bottom: 1px solid $gray-700;

    
            &:hover {
                color: #fff;
                border-color: $gray-400;
                text-decoration: none;
            }
        }
    }
}