// Fix panel content
.ccm-panel-content ul.nav {
    display: block;
}
// ---

// Area fixes
div.ccm-area {
    outline-offset: 0 !important;

    &.ccm-area-highlight {
        outline-offset: 0;
    }
}

div.ccm-area.ccm-area-inline-edit-disabled {
    div.ccm-block-edit-inline-active {
        outline-offset: 0;
    }
}

div.ccm-block-edit {
    outline-offset: 0;

    ul.ccm-edit-mode-inline-commands {
        outline: 1px solid transparent;
        transform: translateX(100%);
        top: 0;
        right: -1px;
        transition: .5s cubic-bezier(.19, 1, .22, 1);
    
        &:hover {
            outline: 1px solid #59ec59;
        }
    
    }
}

div.ccm-area-footer {
    bottom: -36px;
}

div.ccm-block-edit.ccm-menu-item-hover > div.ccm-custom-style-container > ul.ccm-edit-mode-inline-commands, 
div.ccm-block-edit.ccm-block-highlight > div.ccm-custom-style-container > ul.ccm-edit-mode-inline-commands, 
div.ccm-block-edit.ccm-menu-item-hover > ul.ccm-edit-mode-inline-commands, 
div.ccm-block-edit.ccm-block-highlight > ul.ccm-edit-mode-inline-commands {
    outline: 1px solid #59ec59;
}
// ---