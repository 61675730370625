.news-item {
    .news-thumbnail {
        background-size: cover;
        background-position: center;
        min-height: 35vw;

        @include media-breakpoint-up(md) {
            min-height: 0;
        }
    }
}