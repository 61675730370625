section.partners {
    padding-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 5rem;
    }

    h4 {
        @include media-breakpoint-down(md) {
            @include responsive-font-size(2.5rem);
            @include pulse();
            font-weight: $font-weight-bold;
            text-align: center;
        }
    }

    .container {
        .inner {
            @include media-breakpoint-up(lg) {
                @include pulse(3rem);
            }

            .partners-slider {
                .swiper-slide {
                    display: flex;
                    justify-content: center;
                    background: #fff;

                    svg {
                        height: 3rem;
                        width: auto;

                        path {
                            @include basic-transition();
                        }
                    }

                    &:not(:hover) svg path {
                        fill: $gray-400;
                    }

                    &.slide-smaller-1 {
                        svg {
                            padding: 0.1rem 0;
                        }
                    }

                    &.slide-smaller-2 {
                        svg {
                            padding: 0.2rem 0;
                        }
                    }

                    &.slide-smaller-3 {
                        svg {
                            padding: 0.3rem 0;
                        }
                    }

                    &.slide-smaller-4 {
                        svg {
                            padding: 0.4rem 0;
                        }
                    }

                    &.slide-smaller-5 {
                        svg {
                            padding: 0.5rem 0;
                        }
                    }
                }
            }
        }
    }
}
