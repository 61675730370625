@mixin pulse($marginBottom: $spacer) {
    &:before {
        content: '';
        background-image: url('../images/pulse.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: block;
        margin: 0 auto $marginBottom auto;
        height: $spacer * 3;
    }
}
