.lead {
    margin-bottom: $paragraph-margin-bottom * 2;
}

ul.custom-ul li {
    list-style: none;
    position: relative;
    padding-left: 2rem;

    .fa-angle-right {
        position: absolute;
        top: 0.15em;
        left: 0;
        color: $red;
        font-size: 1.2em;
    }
}
