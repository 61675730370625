ul.sitemap-tree {
  list-style-type: none;
  margin: 0 0 20px 0;
  padding: 0;
  overflow: hidden;
}

ul.sitemap-tree li {
  list-style-type: none;
  border-left: 1px dotted #999999;
  margin-left: 11px;
  padding-left: 0px;
  line-height: 20px;
  margin-bottom: 1px;
  position: relative;
  float: none;
  display: block;
  overflow: hidden;
  clear: both;
}

ul.sitemap-tree li:before {
  content: "";
  display: block;
  width: 19px;
  border-top: 1px dotted #999999;
  position: absolute;
  top: 6px;
  left: 0;
  background: none;
  border-radius: 0;
}

//
// nav home
//
ul.sitemap-tree li.nav-home {
  &:before {
    border: 0;
  }

  border-left: 0;
  padding-left: 0;
  margin-left: 3px;
  position: relative;
  @include clearfix;
  overflow: visible;
}
ul.sitemap-tree li.nav-home svg {
  float: left;
  z-index: 2;
  position: relative;
}

ul.sitemap-tree li.nav-home a:before {
  display: none;
}
// ul.sitemap-tree li.nav-home:before {
//   content: '\e802';
//   font-family: $font-icon;
//   font-size: 14px;
//   line-height: 12px;
//   position: absolute;
//   width: auto;
//   top: 1px;
//   border-top: 0;
//   left: -6px;
//   margin-right: 1px;
//   color: $color-theme-primary;
// }

ul.sitemap-tree li.nav-home a {
  margin-left: 12px;
}


//
// rest of the tree
//
ul.sitemap-tree li a {
  // font-size: 16px;
  display: block;
  margin-left: 22px;
  line-height: 15px;
  padding-bottom: 10px;
  float: left;
  color: $body-color;
  border-bottom: 0 !important;
}

ul.sitemap-tree li ul {
  margin-left: 15px;
  padding-left: 0;
  padding-bottom: 15px;
  display: block;
  clear: both;
}


//
// move first li in each ul little higer for nice look
//
ul.sitemap-tree ul > li:first-child {
  padding-top: 4px;
  margin-top: -4px;
}

ul.sitemap-tree ul > li:first-child:before {
  top: 10px;
}

//
// move second li of ul.tree little higer for nice look
//
ul.sitemap-tree > li:first-child + li {
  padding-top: 6px;
  margin-top: -6px;
}

ul.sitemap-tree > li:first-child + li:before {
  top: 12px;
}