/**
 * Want to change something that's not here?
 * You can override every variable from `node_modules/bootstrap/scss/_variables.scss`.
 * Docs: https://getbootstrap.com/docs/4.3/getting-started/theming/
 */

$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-responsive-font-sizes: true;

$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-size-base: 1rem;
$rfs-base-font-size: 0.75rem;
$headings-font-weight: 600;

$lead-font-size: $font-size-base * 1.1;
$lead-font-weight: 400;

// $line-height-base: 1.7;

$rfs-breakpoint: 1440px;

$spacer: 1rem;
// $paragraph-margin-bottom: 2rem;

$transition-base: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

$blue: #227fb6;
$red: #db3832;

$gray-100: #f4f4f4;
$gray-200: #eee;
$gray-300: #ddd;
$gray-400: #ccc;
$gray-500: #aaa;
$gray-600: #666;
$gray-700: #444;
$gray-800: #333;
$gray-900: #222;

$navbar-light-color: $gray-900;
$navbar-light-hover-color: $blue;
$navbar-light-active-color: $blue;
$navbar-light-disabled-color: $gray-600;

// $custom-control-indicator-border-color: #fff;
// $component-active-color: theme-color('primary');
// $component-active-bg: #fff;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1440px //xl: 1200px
);

$container-max-widths: (
    // sm: 540px,
    // md: 720px,
    // lg: 960px,
    xl: 1440px //xl: 1140px
);

$grid-gutter-width: 30px;

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1rem;

$hero-home-margins-bottom: (
    xs: -15px,
    sm: -2rem,
    lg: -8vw
);

$hero-page-margins-bottom: (
    xs: -15px,
    sm: -2rem,
    lg: -4rem
);

$form-group-margin-bottom: 10px;
$input-placeholder-color: $gray-500;

$custom-file-text: (
    pl: 'Wybierz'
);
