a[href^="tel"] {
    color: inherit !important;
    border: 0 !important;
    text-decoration: none !important;
}

.show-number {
    font-size: 0.75em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 0 4px 1px;
    position: relative;
    top: -1px;
    text-decoration: none;
    @include basic-transition();

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.2);
    }
}
