.btn {
    &.btn-icon-left {
        .svg-inline--fa {
            margin-right: $spacer;
            font-size: 1.5em;
            vertical-align: bottom;
        }
    }

    &.btn-icon-right {
        .svg-inline--fa {
            margin-left: $spacer;
            font-size: 1.5em;
            vertical-align: bottom;
        }
    }
}
